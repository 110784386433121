<template>
  <v-container fluid class="pa-0">
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="6"
        v-for="place in getPlaces"
        :key="place._id"
        class="ma-n2"
      >
        <v-card tile dark color="white" hover>
          <v-card-title class="grey--text">
            <v-icon left :color="place.color">fas fa-map-marker-alt</v-icon>
            <span class="text-h6 blue-grey--text">{{ place.placeName }}</span>
          </v-card-title>
          <v-card-text class="grey--text text-center">
            <template
              v-for="serviceCategory in getServiceCategories(place.services)"
            >
              <v-badge
                inline
                :content="serviceCategory.serviceCounter"
                :color="serviceCategory.color"
                :key="serviceCategory._id"
              >
                <v-icon :color="serviceCategory.color">{{
                  serviceCategory.icon
                }}</v-icon>
              </v-badge>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              tile
              dark
              color="blue-grey darken-4"
              @click="navigateTo(place._id)"
              >ΠΡΟΒΟΛΗ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    serviceCategories: [],
  }),
  computed: {
    getPlaces() {
      return this.$store.getters.getPlaces;
    },
  },
  methods: {
    getServiceCategories(services) {
      let serviceCategories = [];
      for (const service of services) {
        let serviceCategoryIndex = serviceCategories.findIndex(
          (serviceCategoryElement) => {
            return serviceCategoryElement._id == service.serviceCategory._id;
          }
        );
        if (serviceCategoryIndex == -1) {
          serviceCategories.push(
            Object.assign({ serviceCounter: 1 }, service.serviceCategory)
          );
        } else {
          serviceCategories[serviceCategoryIndex].serviceCounter++;
        }
      }
      return serviceCategories;
    },
    navigateTo(placeID) {
      this.$router.push(`/place/${placeID}`);
    },
  },
};
</script>