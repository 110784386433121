import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from "vue-cookies";

import Signin from '../views/Signin.vue'
import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import Services from '../views/services/Services.vue'
import Places from '../views/services/Places.vue'
import Place from '../views/services/Place.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: "signin",
    component: Signin,
    beforeEnter: (to, from, next) => {
      if (VueCookies.get('AUTHTOKEN') != null) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/',
    component: Main,
    beforeEnter: (to, from, next) => {
      if (VueCookies.get('AUTHTOKEN') == null) {
        next('/signin');
      } else {
        next();
      }
    },
    children: [
      // {
      //   path: "",
      //   name: "home",
      //   component: Home
      // },
      {
        path: "",
        component: Services,
        children: [
          {
            path: "",
            name: "places",
            component: Places
          },
          {
            path: "place/:placeid",
            name: "place",
            component: Place
          }
        ]
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
