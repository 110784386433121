import axios from 'axios';
import router from '../router/index';
import VueCookies from "vue-cookies";

const http = axios.create({
  baseURL: "https://smartservices-api.appside.gr"
})

http.interceptors.request.use(async config => {
  const authToken = VueCookies.get("AUTHTOKEN");
  if (authToken != null) {
    config.headers = {
      'Authorization': `Bearer ${authToken}`,
    }
  }
  return config;
}, error => {
  Promise.reject(error)
})

http.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response != undefined) {
    let responseStatus = error.response.status;
    console.log(responseStatus);
    if (responseStatus == 500) {
      return Promise.reject("Πρόβλημα με τον διακομιστή");
    } else if (responseStatus == 401) {
      VueCookies.remove("AUTHTOKEN");
      localStorage.removeItem("fullName");
      router.push("/signin");
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("Κάτι δεν πήγε καλά...")
  }
})

export default http;