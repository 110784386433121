import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    places: [],
  },
  getters: {
    getPlaces(state) {
      return state.places;
    },
    getPlaceByID: (state) => (placeID) => {
      return state.places.find(placeElement => {
        return placeElement._id == placeID
      });
    },
  },
  mutations: {
    SET_PLACE(state, place) {
      place.devices = [];
      place.services = [];
      let index = state.places.findIndex(placeElement => {
        return placeElement._id == place._id
      })
      if (index == -1) {
        state.places.push(place);
      } else {
        Vue.set(state.places, index, place);
      }
    },
    SET_DEVICE(state, device) {
      let placeIndex = state.places.findIndex(placeElement => {
        return placeElement._id == device._placeID
      })
      if (placeIndex != -1) {
        let deviceIndex = state.places[placeIndex].devices.findIndex(deviceElement => {
          return deviceElement._id == device._id
        })
        if (deviceIndex == -1) {
          state.places[placeIndex].devices.push(device);
        } else {
          Vue.set(state.places[placeIndex].devices, deviceIndex, device);
        }
      }
    },
    SET_SERVICE(state, service) {
      for (const place of state.places) {
        let deviceIndex = place.devices.findIndex(deviceElement => {
          return deviceElement._id == service._deviceID
        })
        if (deviceIndex != -1) {
          let serviceIndex = place.services.findIndex(serviceElement => {
            return serviceElement._id == service._id
          })
          if (serviceIndex == -1) {
            place.services.push(service);
          } else {
            Vue.set(place.services, serviceIndex, device);
          }
          break;
        }
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
