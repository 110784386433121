<template>
  <v-container fluid class="fill-height pa-0">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" md="6" class="text-center">
        <ListPlaces />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListPlaces from "@/components/services/ListPlaces";
export default {
  components: {
    ListPlaces,
  },
};
</script>