<template>
  <v-app>
    <Appbar />
    <router-view />
  </v-app>
</template>

<script>
import Appbar from "@/components/Appbar";
export default {
  components: {
    Appbar,
  },
  methods: {
    async getInitialData() {
      try {
        let { data: places } = await this.$http.get("/group/places");
        let { data: devices } = await this.$http.get("/group/devices");
        let { data: services } = await this.$http.get("/group/services");
        for (const place of places) {
          this.$store.commit("SET_PLACE", place);
        }
        for (const device of devices) {
          this.$store.commit("SET_DEVICE", device);
        }
        for (const service of services) {
          this.$store.commit("SET_SERVICE", service);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>