<template>
  <v-app-bar color="blue-grey darken-4" dark elevation="0" app>
    <v-icon left>fas fa-satellite-dish</v-icon>
    <v-toolbar-title>Smart Services</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="signout()">
      <v-icon>fas fa-sign-out-alt</v-icon>
    </v-btn>
    <template v-slot:extension>
      <v-tabs
        color="white"
        background-color="blue-grey darken-4"
        dark
        centered
        icons-and-text
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab to="/">
          <span>ΑΡΧΙΚΗ</span>
          <v-icon color="blue">fab fa-elementor</v-icon>
        </v-tab>
        <!-- <v-tab to="/services">
          <span>SERVICES</span>
          <v-icon color="green">fas fa-satellite-dish</v-icon>
        </v-tab> -->
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    signout() {
      this.$cookies.remove("AUTHTOKEN");
      localStorage.removeItem("fullName");
      this.$router.push("/signin");
    },
  },
};
</script>