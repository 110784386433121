<template>
  <v-container fluid class="pa-0">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <TabPlaces />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" class="mt-5">
        <v-container fluid class="pa-0">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-toolbar dark color="blue-grey darken-4">
                <v-toolbar-title>
                  <v-icon left>fas fa-solar-panel</v-icon>
                  SOLAR TRACKERS
                </v-toolbar-title>
                <v-spacer />
                <v-icon @click="initPlace()">fas fa-sync</v-icon>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="service in services"
              :key="service._id"
              class="ma-n2"
            >
              <v-card tile dark color="white" hover>
                <v-card-title class="grey--text text-center justify-center">
                  <v-avatar color="blue-grey darken-4" size="30">
                    <span class="text-h6 white--text">{{
                      service.serviceName
                    }}</span>
                  </v-avatar>
                  <span
                    v-if="checkConnection(service.syncDatetime) == true"
                    class="ml-1"
                    >NO SIGNAL</span
                  >
                  <span v-else-if="service.serviceMode == 1" class="ml-1"
                    >AUTO</span
                  >
                  <span v-else-if="service.serviceMode == 2" class="ml-1"
                    >MANUAL</span
                  >
                  <span v-else-if="service.serviceMode == 3" class="ml-1"
                    >INIT</span
                  >
                  <v-spacer />
                  <span class="text-caption black--text">{{
                    service.syncDatetime
                  }}</span>
                </v-card-title>
                <v-card-text class="grey--text text-center">
                  <v-container fluid>
                    <v-row align="center" justify="center" class="white--text">
                      <v-progress-circular
                        rotate="40"
                        size="150"
                        width="15"
                        :value="getCircle(service.currentPoint)"
                        :color="
                          getColor(
                            service.serviceStatus,
                            service.serviceMode,
                            service.syncDatetime
                          )
                        "
                      >
                        {{ (parseInt(service.currentPoint) - 1) * 6 + 60 }}&deg;
                        | {{ service.currentPoint }}
                      </v-progress-circular>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pa-4 mt-n6">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="
                          checkConnection(service.syncDatetime) == true
                        "
                        tile
                        block
                        dark
                        color="blue-grey darken-4"
                        v-bind="attrs"
                        v-on="on"
                      >
                        ΕΠΙΛΟΓΕΣ
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="setAuto(service._serviceID)"
                        v-if="
                          service.serviceMode == 2 || service.serviceMode == 3
                        "
                      >
                        <v-list-item-title
                          >ΑΛΛΑΓΗ ΣΕ ΑΥΤΟΜΑΤΟ</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        @click="setManual(service._serviceID)"
                        v-if="
                          service.serviceMode == 1 || service.serviceMode == 3
                        "
                      >
                        <v-list-item-title
                          >ΑΛΛΑΓΗ ΣΕ ΧΕΙΡΟΚΙΝΗΤΟ</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="setInit(service._serviceID)" v-if="service.serviceMode != 3">
                        <v-list-item-title>ΑΡΧΙΚΟΠΟΙΗΣΗ</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          changeCurrentPoint(
                            service._serviceID,
                            service.currentPoint
                          )
                        "
                      >
                        <v-list-item-title>ΑΛΛΑΓΗ ΒΙΔΑΣ</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="clearAlert(service._serviceID)"
                        v-if="service.serviceStatus == 4"
                      >
                        <v-list-item-title
                          >ΑΠΕΝΕΡΓΟΙΠΟΙΗΣΗ ALERT</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="azimuthSync(service._serviceID)">
                        <v-list-item-title>ΚΙΝΗΣΗ</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="refreshData(service._serviceID)">
                        <v-list-item-title>REFRESH</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card tile>
        <v-card-title>
          <span class="headline">Αλλαγή Βίδας</span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-select
                  v-model="currentPoint"
                  :items="pointList"
                  type="number"
                  outlined
                  filled
                  placeholder="Βίδα"
                ></v-select>
              </v-col>
              <v-col cols="12" class="mt-n10">
                <v-btn
                  color="blue darken-1"
                  block
                  tile
                  dark
                  @click="setNewPoint()"
                >
                  ΑΛΛΑΓΗ
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TabPlaces from "@/components/services/TabPlaces";
export default {
  components: {
    TabPlaces,
  },
  data: () => ({
    services: [],
    dialog: false,
    currentPoint: 0,
    serviceID: null,
    pointList: [
      -5,
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
    ],
  }),
  watch: {
    $route: {
      immediate: true,
      handler(value) {
        this.initPlace();
      },
    },
  },
  // computed: {
  //   getSortedServices() {
  //     return this.services.sort((a, b) => {
  //       if (parseInt(a.serviceName) > parseInt(b.serviceName)) {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     });
  //   },
  // },
  methods: {
    async azimuthSync(serviceID) {
      let { data } = await this.$http.post(
        `/solartracker/${serviceID}/azimuthsync`
      );
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    async refreshData(serviceID) {
      let { data } = await this.$http.post(
        `/solartracker/${serviceID}/syncdata`
      );
      setTimeout(() => {
        this.initPlace();
      }, 3000);
    },
    async getService(service) {
      this.services = [];
      let { data } = await this.$http.get(`/group/service/${service._id}`);
      this.services.push({
        _id: data._id,
        _serviceID: data._serviceID,
        serviceName: service.serviceName,
        currentPoint: data.currentPoint,
        syncDatetime: data.syncDatetime,
        serviceStatus: data.status,
        serviceMode: data.mode,
      });
    },
    getCircle(currentPoint) {
      return ((parseInt(currentPoint) - 1) * 6 + 60) / 2.8;
    },
    getColor(status, mode, syncDatetime) {
      if (this.checkConnection(syncDatetime) == true) {
        return "grey darken-1";
      }
      if (status == 1) {
        return "blue pa-0";
      } else if (status == 2) {
        return "green pa-0";
      } else if (status == 3) {
        return "orange pa-0";
      } else if (status == 4) {
        return "red pa-0";
      }
    },
    checkConnection(syncDatetime) {
      let splittedSyncDatetime = syncDatetime.split(/\D/);
      let splittedCurrentDatetime = this.getCurrentDatetime().split(/\D/);
      let syncDatetimeObject = new Date(
        splittedSyncDatetime[0],
        --splittedSyncDatetime[1],
        splittedSyncDatetime[2],
        splittedSyncDatetime[3],
        splittedSyncDatetime[4]
      );
      let currentDatetimeObject = new Date(
        splittedCurrentDatetime[0],
        --splittedCurrentDatetime[1],
        splittedCurrentDatetime[2],
        splittedCurrentDatetime[3],
        splittedCurrentDatetime[4]
      );

      if (Math.abs((syncDatetimeObject - currentDatetimeObject) / 36e5) > 1) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentDatetime() {
      let dateObject = new Date();

      let year = dateObject.getFullYear();
      let month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
      let day = ("0" + dateObject.getDate()).slice(-2);
      let hours = ("0" + dateObject.getHours()).slice(-2);
      let minutes = ("0" + dateObject.getMinutes()).slice(-2);

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    changeCurrentPoint(serviceID, currentPoint) {
      this.dialog = true;
      this.currentPoint = currentPoint;
      this.serviceID = serviceID;
    },
    async setInit(serviceID) {
      let { data } = await this.$http.post(`/solartracker/${serviceID}/init`);
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    async setAuto(serviceID) {
      let { data } = await this.$http.post(`/solartracker/${serviceID}/auto`);
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    async setManual(serviceID) {
      let { data } = await this.$http.post(`/solartracker/${serviceID}/manual`);
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    async setNewPoint() {
      let {
        data,
      } = await this.$http.post(
        `/solartracker/${this.serviceID}/changecurrentpoint`,
        { newPoint: this.currentPoint }
      );
      this.dialog = false;
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    async clearAlert(serviceID) {
      let { data } = await this.$http.post(
        `/solartracker/${serviceID}/clearalerts`
      );
      setTimeout(() => {
        this.refreshData(serviceID);
      }, 3000);
    },
    initPlace() {
      let placeID = this.$route.params.placeid;
      let item = this.$store.getters.getPlaceByID(placeID);
      if (item == undefined) {
        setTimeout(() => {
          let item = this.$store.getters.getPlaceByID(placeID);
          for (const service of item.services) {
            this.getService(service);
          }
        }, 3000);
      } else {
        for (const service of item.services) {
          this.getService(service);
        }
      }
    },
  },
};
</script>