<template>
  <v-tabs dark background-color="blue-grey darken-2">
    <v-tab
      v-for="place in getPlaces"
      :key="place._id"
      :to="`/place/${place._id}`"
    >
      <span>{{ place.placeName }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    getPlaces() {
      return this.$store.getters.getPlaces;
    },
  },
  watch: {
  },
  methods: {
  },
};
</script>