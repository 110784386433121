<template>
  <v-app>
    <v-main class="grey lighten-3" id="signinContainer">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" sm="6" md="4" lg="4" xl="3">
            <FormSignin />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
#signinContainer {
  background-image: url("~@/assets/signin-background.jpg");
  background-size: cover;
  background-position: center;
}
</style>

<script>
import FormSignin from "@/components/FormSignin";
export default {
  components: {
    FormSignin
  }
};
</script>