<template>
  <v-container fluid class="pa-0">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="text-center">
        <h1 class="display-1 white--text">
          <v-icon large color="white" left>fas fa-satellite-dish</v-icon>
          Smart Services
        </h1>
      </v-col>
      <v-col cols="12" class="mt-5">
        <v-card tile color="white">
          <v-card-text>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-col cols="12">
                <v-text-field
                  v-model="username"
                  type="text"
                  :rules="textRules"
                  filled
                  rounded
                  color="blue accent-4"
                  placeholder="Όνομα χρήστη"
                  prepend-inner-icon="fas fa-user-alt"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  type="password"
                  :rules="passwordRules"
                  filled
                  rounded
                  color="blue accent-4"
                  placeholder="Κωδικός πρόσβασης"
                  prepend-inner-icon="fas fa-key"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="alert">
                <v-alert v-model="alert" outlined color="red" dismissible>{{
                  alertMessage
                }}</v-alert>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              tile
              block
              dark
              color="blue accent-4"
              :loading="submitButtonLoading"
              @click="signin()"
              >ΕΙΣΟΔΟΣ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isFormValid: false,
    username: "",
    password: "",
    textRules: [(v) => !!v || "Εισάγετε το όνομα χρήστη"],
    passwordRules: [(v) => !!v || "Εισάγετε τον κωδικό πρόσβασης"],
    submitButtonLoading: false,
    alert: false,
    alertMessage: "",
  }),
  methods: {
    async signin() {
      if (this.$refs.form.validate()) {
        try {
          this.submitButtonLoading = true;
          let { data } = await this.$http.post("/signin", {
            username: this.username,
            password: this.password,
          });
          this.$cookies.set("AUTHTOKEN", data.token);
          localStorage.setItem("fullName", data.fullName);
          this.$router.push("/");
        } catch (error) {
          this.alert = true;
          if (error.response != undefined) {
            this.alertMessage = error.response.data;
          } else {
            this.alertMessage = error;
          }
        } finally {
          this.submitButtonLoading = false;
        }
      }
    },
  },
};
</script>