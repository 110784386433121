<template>
  <v-main class="grey lighten-2">
    <v-container fluid class="fill-height pa-0">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" class="text-center">
          <v-icon size="80">fas fa-satellite-dish</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({}),
};
</script>